import { timeCircleCounter } from '../modules/TimeCircleCounter';

hp(window).on('load', () => {
    require('../partials/gpt-help-modal');

    timeCircleCounter(hp('#DateCountdown'))

    if (hp('html').attr('lang') != 'en') {
        const TENDER_TABLE = hp('.tenders-table')

        const MORE_BTN = TENDER_TABLE.find('tfoot td span')
        const TABLE_ROWS = TENDER_TABLE.find('tbody tr')

        MORE_BTN.on('click', (e) => {
            if (TENDER_TABLE.hasClass('collapsed')) {

                TABLE_ROWS.each((row, index) => {
                    if (index + 1 > 3) {
                        hp(row).addClass('d-none')
                    }
                })

                TENDER_TABLE.removeClass('collapsed')

                return
            }
            TABLE_ROWS.each((row, index) => {
                if (index + 1 > 3) {
                    hp(row).removeClass('d-none')
                }
            })

            TENDER_TABLE.addClass('collapsed')
        })
    }
})
