

function TimeCircleCounter(element) {
    this.elem = hp(element);

    this.refDate = new Date();

    this.params = {
        Days: 0,
        Hours: 0,
        Minutes: 0,
        Seconds: 0,
    };

    this.allUnits = ["Days", "Hours", "Minutes", "Seconds"];

    this.nextUnits = {
        Seconds: "Minutes",
        Minutes: "Hours",
        Hours: "Days",
        Days: "Years"
    };

    this.secondsIn = {
        Seconds: 1,
        Minutes: 60,
        Hours: 3600,
        Days: 86400,
        Months: 2678400,
        Years: 31536000
    };

    this.init();
}

TimeCircleCounter.prototype = {
    init: function () {
        this._parseDate(this.elem.data('date'));

        this._parseTimes()

        this.start()
    },

    start: function () {
        let wrapper = this._getWrapperBlock()

        let timeBlocks = this._getTimesBlock()

        timeBlocks.each((elem, index) => {
            wrapper.append(elem.outerHTML)
        })

        this.elem.append(wrapper.elem.outerHTML)

        setInterval(() => {
            this.changeTime()
            this.drawArcs(this.elem.find('canvas'))
        }, 17)
    },

    changeTime: function () {
        this._parseTimes()

        this.allUnits.map((unit) => this.elem.find('.timer_' + unit + ' .time-count').text(parseInt(this.params[unit])))
    },

    drawArcs: function (canvases) {
        let circleBorderWidth = 5;
        let circleBorderColor = '#365b961a';

        let circleWidth = 183;
        let circleCenter = circleWidth / 2;
        let circleRadius = circleCenter - circleBorderWidth;

        let arcStarterPointer = Math.PI * 1.4968

        hp(canvases).each((canvas, index) => {
            let canvasUnitType = hp(canvas).attr('id').replace('timer_', '')

            canvas.width = canvas.height = circleWidth;

            const ctx = canvas.getContext('2d')

            ctx.strokeStyle = circleBorderColor;
            ctx.lineWidth = 1;

            ctx.beginPath()
            ctx.arc(circleCenter, circleCenter, circleRadius, arcStarterPointer, Math.PI * 2 + arcStarterPointer, true)
            ctx.stroke();

            ctx.strokeStyle = '#365B95';
            ctx.lineWidth = circleBorderWidth;

            ctx.beginPath()
            ctx.arc(circleCenter, circleCenter, circleRadius, arcStarterPointer, this._convertPercentToRadians(this._getPercent(canvasUnitType)) + arcStarterPointer, false)
            ctx.stroke();
        })
    },

    _parseDate: function (date) {
        let tempDate = new Date(date)

        if (tempDate == 'Invalid Date') {
            this.refDate = new Date('2009-09-17 12:00:00')
        } else {
            this.refDate = tempDate
        }
    },

    _parseTimes: function () {
        let diff = (new Date() - this.refDate) / 1000
        let total_duration = this.secondsIn.Years
        let units = this.allUnits

        let greater_unit = null;

        Array.from(units).forEach((unit) => {
            let maxUnits = total_duration / this.secondsIn[unit];

            if (greater_unit !== null) {
                maxUnits = this.secondsIn[greater_unit] / this.secondsIn[unit]
            }

            let curUnits = (diff / this.secondsIn[unit]);

            if (unit !== "Days") {
                curUnits = curUnits % maxUnits;
            }

            this.params[unit] = Math.abs(curUnits);

            greater_unit = unit;
        })
    },

    _getWrapperBlock: function () {
        return hp(document.createElement('div')).addClass('time_circles')
    },

    _getTimesBlock: function () {
        let blocks = []

        let units = Object.keys(this.params)

        units.forEach((unit, index) => {
            let unitBlock = hp(document.createElement('div'))
            let time = this.params[unit]

            unitBlock.addClass('timer_' + unit)
            unitBlock.append(`<canvas id="${'timer_' + unit}"></canvas><span class="time-count">${time}</span>`)

            blocks.push(unitBlock.elem)
        })

        return hp(blocks)
    },

    _convertPercentToRadians: function (percent) {
        return (percent / 100) * (Math.PI * 2)
    },

    _calculatePercent: function (unitTime, unitType) {
        let nextUnit = this.nextUnits[unitType]
        let nextUnitMaxTime = this.secondsIn[nextUnit]

        return (unitTime * 100) / nextUnitMaxTime
    },

    _getPercent: function (unit) {
        let circlePercent = 100;

        if (unit !== 'Days') {
            circlePercent = this._calculatePercent(this.params[unit] * this.secondsIn[unit], unit)
        }

        return circlePercent;
    }
}

export let timeCircleCounter = (element) => new TimeCircleCounter(element);
